.Beat {
  position: relative;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  padding: 8px;
  box-shadow: inset 0 0 0 1px var(--primary-subtle);
}
.Beat.editing {
  box-shadow: inset 0 0 0 1px var(--hover);
}

.Beat.editing.playing {
  box-shadow: inset 0 0 0 1px var(--white);
}

.Beat.playing {
  box-shadow: inset 0 0 0 1px var(--primary);
}

.Beat::after {
  content: "";
  width: 16px;
  height: 4px;
  position: absolute;
  bottom: -16px;
  left: calc(50% - 8px);
  border-radius: 2px;
  background: var(--bg-dark);
}
.Beat:hover::after {
  background: var(--primary-subtle);
}
.Beat.editing::after {
  background: var(--white);
}

.beat-pad {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10px;
  height: 10px;
  box-sizing: border-box;
  box-shadow: inherit;
  border-radius: 5px;
  pointer-events: none;

  outline: none;
  appearance: none;
  border: none;
  background: var(--bg-dark);
  color: inherit;
  font-size: 16px;
  font-family: "Courier" !important;
}
.beat-pad:nth-child(1) {
  top: -3px;
  left: calc(50% - 5px);
}
.beat-pad:nth-child(2) {
  top: 0px;
  right: 0px;
}
.beat-pad:nth-child(3) {
  top: calc(50% - 5px);
  right: -3px;
}
.beat-pad:nth-child(4) {
  bottom: 0px;
  right: 0px;
}
.beat-pad:nth-child(5) {
  bottom: -3px;
  left: calc(50% - 5px);
}
.beat-pad:nth-child(6) {
  bottom: 0px;
  left: 0px;
}
.beat-pad:nth-child(7) {
  top: calc(50% - 5px);
  left: -3px;
}
.beat-pad:nth-child(8) {
  top: 0px;
  left: 0px;
}
.beat-pad:nth-child(9) {
  top: calc(50% - 5px);
  left: calc(50% - 5px);;
}

.beat-pad.sequenced {
  background: var(--hover);
  box-shadow: inset 0 0 0 1px var(--hover);
}

.Beat.editing .beat-pad.beat-pad.sequenced {
  background: var(--active);
  box-shadow: inset 0 0 0 1px var(--active);
}