/* https://lospec.com/palette-list/blessing */
:root {
  --bg-dark: #74569B;
  --bg-light: #523874;

  --primary: #D8BFD8;
  --primary-subtle: #8D70AA;
  --hover: #FFB3CB;
  --active: #96FBC7;
  --white: #F7FFAE;

}

* {cursor: none;}

html {
  overflow: hidden;
  background: var(--bg-dark);
  color: var(--primary);
  -webkit-tap-highlight-color: rgba(0,0,0,0) !important; 
  -webkit-tap-highlight-color: transparent !important;
  margin: 0;
  padding: 0;

  font-family: "Inconsolata";
  letter-spacing: .05rem;

  user-select: none;
  touch-action: none
}

a,
a:visited,
a:active {
  color: inherit;
  text-decoration: none;
}

a:hover {
  text-decoration: underline;
}

#custom-cursor {
  width: 8px;
  height: 8px;
  border-radius: 4px;
  background: var(--hover);
  box-shadow: 0 0 0 1px var(--bg-dark);
}

h1, h2, h3, h4, h5 {
  margin: 0;
  line-height: 1;
  font-weight: 400;
  user-select: none;
}

h1 { font-size: 16px;}

p {
  margin: .5em 0;
  line-height: 1.25;
  font-weight: 400;
  user-select: none;
}

body {
  font-size: 16px;
  line-height: 1;
  height: 100vh;
  max-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;

  margin: 0;
  padding: 0;
  overflow: hidden;
  touch-action: none
}

main {
  min-height: 128px;
  min-width: 128px;
  max-width: calc(100vw - 64px);
  padding: 32px;
  background: var(--bg-dark);
  box-shadow: inset 0 0 0 1px var(--primary);
  border-radius: 24px 24px 128px 24px;

  position: relative;

  opacity: 1;
  transition: opacity .2s ease-out;
}

main.loading {
  pointer-events: none;
  opacity: .25;

  animation-duration: 1s;
  animation-name: loading;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: ease-out;
}

@keyframes loading {
  from {
    opacity: .25;
  }

  to {
    opacity: .5;
  }
}

#Pads {
  position: relative;
  width: 256px;
  height: 256px;
  align-self: center;
  border-radius: 50%;
  margin: 44px;
  box-shadow: inset 0 0 0 1px var(--primary);
}

.Pad {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88px;
  height: 88px;
  box-sizing: border-box;
  box-shadow: inset 0 0 0 1px var(--primary);
  border-radius: 40px;
  cursor: none;

  outline: none;
  appearance: none;
  border: none;
  background: var(--bg-dark);
  color: inherit;
  font-size: 16px;
  font-family: "Courier" !important;
}
.Pad:nth-child(1) {
  top: -44px;
  left: calc(50% - 44px);
}
.Pad:nth-child(2) {
  top: -8px;
  right: -8px;
}
.Pad:nth-child(3) {
  top: calc(50% - 44px);
  right: -44px;
}
.Pad:nth-child(4) {
  bottom: -8px;
  right: -8px;
}
.Pad:nth-child(5) {
  bottom: -44px;
  left: calc(50% - 44px);
}
.Pad:nth-child(6) {
  bottom: -8px;
  left: -8px;
}
.Pad:nth-child(7) {
  top: calc(50% - 44px);
  left: -44px;
}
.Pad:nth-child(8) {
  top: -8px;
  left: -8px;
}
.Pad:nth-child(9) {
  top: calc(50% - 44px);
  left: calc(50% - 44px);;
}

.Pad .note-label {
  opacity: .5;
  font-size: 12px;
  position: absolute;
  text-align: center;
  left: 0;
  right: 0;
  top: 16px;
}

.Pad:hover {
  color: var(--hover);
  box-shadow: inset 0 0 0 1px var(--hover);
}

.Pad.ghost {
  background: var(--primary-subtle);
}
.Pad.sequenced {
  color: var(--bg-light);
  background: var(--hover);
  box-shadow: inset 0 0 0 0 var(--hover);
}
/* .Pad.sequenced:active {
  color: var(--bg-light);
  background: var(--hover);
  box-shadow: inset 0 0 0 0 var(--hover);
} */
.Pad.sequenced.ghost {
  background: var(--white);
  /* box-shadow: inset 0 0 0 2px var(--active); */
}
.Pad:active,
.Pad.active {
  color: var(--bg-light);
  background: var(--active);
  box-shadow: inset 0 0 0 0 var(--primary);
}

.video {
  max-width: 1px;
  max-height: 1px;
  opacity: 0;
}

#Control {
  width: 80px;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
}

#Control #Symbol {
  display: block;
  position: relative;
}
#Control.stopped #Symbol { opacity: 1; }
#Control.stopped #Symbol svg { pointer-events: none;}
#Control.stopped #Symbol svg path {
  fill: var(--primary);
  transition: fill 1s ease-out,
              filter 1s ease-out;
}

#Control.playing #Symbol { opacity: 1; }
#Control.playing #Symbol svg path {
  fill: var(--white);
  transition: fill .15s ease-out,
              filter .15s ease-out;
}

#Control.stopped {
  filter: drop-shadow(0 0 0 var(--bg-dark));
}
#Control.playing {
  filter: drop-shadow(0 0 10px var(--white));
}

#info {
  position: absolute;
  top: 16px;
  left: 16px;
  max-width: 30vw;
  min-width: 343px;
}
  #info h1 { 
    display: inline;
    transition: color 1s ease-out;
  }
  #info h1 strong {pointer-events: none; user-select: none;}
  #info p {
    font-size: 14px;
    line-height: 1.5;
    margin: 8px 0;
    opacity: 0;

    transition: opacity .5s ease-out;
  }
  #info em {
    color: var(--hover);
    display: block;
    margin-bottom: 8px;
  }
  #info svg {
    height: 14px;
    overflow: visible;
    margin: 0 8px;
    transform: scale(0.75);
  }
  /* #info span:hover { cursor: help !important; } */
  #info span:hover h1 { 
    color: var(--active); 
    transition: color 0s ease-out;
  }
  #info span:hover + p {
    opacity: 1;
    transition: opacity 0s ease-out;
  }

@media screen and (max-width: 375px) {

  #custom-cursor { display: none; }

  main {
    min-width: 100vw;
    box-sizing: border-box;
    position: fixed;
    top: 8px;
    right: 0;
    bottom: 88px;
    left: 0;
    align-items: center;
    justify-content: center;
    display: flex !important;
    overflow: hidden;
    box-shadow: none;

    border: 1px solid;
    transform: scale(0.9) translateY(-20px);

    transition: bottom .5s ease-out,
                opacity .2s ease-out;
  }

  main > .Stack {
    position: absolute;
    top: 24px;
    bottom: 32px;
  }

  #Sequencer { transform: scale(0.9);}

  #wrapper main .controls {
    position: absolute !important;
    bottom: -8px;
  }

  .controls > #Control {
    width: 96px;
    height: 96px;
    max-width: 96px;
    max-height: 96px;
    border-radius: 48px;
    overflow: hidden;
  }

  #info {
    left: 24px;
    right: 24px;
    bottom: -112px;
    top:unset;
    min-width: unset;
    max-width: unset;

    text-align: center;
    transition: bottom .5s ease-out;
  }

  #info .Tappable-inactive:hover + p {
    opacity: 0;
    transition: opacity .5s ease-out;
  }
  

  #wrapper.active main {
    bottom: 188px;
    transition: bottom .25s ease-out;
  }

  #wrapper.active #info {
    bottom: 32px;
    transition: bottom .25s ease-out;
  }

  #wrapper.active #info h1{
    color: var(--active);
  }

  #wrapper.active #info p {
    opacity: 1 !important;
    transition: opacity .25s ease-out;
  }
}