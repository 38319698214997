.Stack {
  position: relative;
  display: flex !important;
}
.Stack > * {
  display: block;
  margin: 0;
}
.Stack.center {align-items: center;}
.Stack.wrap {flex-wrap: wrap;}

.Stack.vertical { flex-direction: column; }

.Stack.vertical.tiny   > * {margin-bottom: 4px;}
.Stack.vertical.small  > * {margin-bottom: 8px;}
.Stack.vertical.medium > * {margin-bottom: 16px;}
.Stack.vertical.medium-24 > * {margin-bottom: 24px;}
.Stack.vertical.large  > * {margin-bottom: 32px;}
.Stack.vertical.huge  > * {margin-bottom: 64px;}
.Stack.vertical  > *:last-child {margin-bottom: 0px;}


.Stack.row { flex-direction: row; }

.Stack.horizontal.tiny   > * {margin-right: 4px;}
.Stack.horizontal.small  > * {margin-right: 8px;}
.Stack.horizontal.medium > * {margin-right: 16px;}
.Stack.horizontal.medium-24 > * {margin-right: 24px;}
.Stack.horizontal.large  > * {margin-right: 32px;}
.Stack.horizontal.huge  > * {margin-right: 64px;}
.Stack.horizontal > *:last-child {margin-right: 0px;}

.Stack.wrap.horizontal.tiny   > * {margin-right: 4px; margin-bottom: 4px;}
.Stack.wrap.horizontal.small  > * {margin-right: 8px; margin-bottom: 8px;}
.Stack.wrap.horizontal.medium > * {margin-right: 16px; margin-bottom: 16px;}
.Stack.wrap.horizontal.medium-24 > * {margin-right: 24px; margin-bottom: 32px;}
.Stack.wrap.horizontal.large  > * {margin-right: 32px; margin-bottom: 32px;}
.Stack.wrap.horizontal.huge  > * {margin-right: 64px; margin-bottom: 64px;}
.Stack.wrap.horizontal > *:last-child {margin-right: 0px;}